export const GRAPHQL_URL_WS = 
// `wss://devapi.chillfitrave.com/graphql`;
`wss://api.chillfitrave.com/graphql`;

export const GRAPHQL_URL =
  // 'http://192.168.0.110:8000/graphql'
  // "http://localhost:8000/graphql";
  "https://api.chillfitrave.com/graphql";
  // "https://devapi.chillfitrave.com/graphql";

export const S3_BASE_URL =
  "https://assestchillfitrave.s3.amazonaws.com/";
//lqh1204@outlook.com
export const OrderStatus = {
  PreOrder: "Pre order",
  Pending: "Preparing to ship",
  Partial: "Partial shipped",
  Shipped: "Shipped",
  Delivered: "Delivered",
  Canceled: "Canceled",
  Deleted: "Deleted",
};
export const couponStatus = {
  Pending: "Pending",
  Accepted: "Accepted",
  Canceled: "Canceled",
  Expired: "Expired",
  Denied: "Denied",
  Deleted: "Deleted",
  Paused: "Paused",
  Paid: "Paid",
};

export const userRole = {
  superAdmin: "SuperAdmin",
  Admin: "Admin",
  Moderator: "Moderator",
  collaborator: "Collaborator",
};

export const catSubType = {
  fullSet: 'FULL_SET',
  top: 'TOP',
  bottom: 'BOTTOM',
  mixed: 'MIXED'
}
